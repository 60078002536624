<template>
  <b-container class="bv-example-row">
    <b-row class="infoRow">
      <b-col :cols="isMobile ? '12' : '3'" :class="isMobile ? 'mBtnsCol' : 'btnCol'">
        <vs-button
          color="amazon"
          icon
          :size="isMobile ? 'mini' : 'default'"
          relief
          block
          v-if="profileType === 3"
          to="/ayuntamiento"
        >
          <i class="fas fa-city"></i> Ayunts.
        </vs-button>
        <vs-button
          icon
          @click="onEdit"
          v-if="!isEditMode && !isExternal"
          relief
          block
          :size="isMobile ? 'mini' : 'default'"
          animation-type="rotate"
        >
          <i class="fas fa-pen"></i> Edit
          <template #animate>
            <i class="fas fa-pen"></i>
          </template>
        </vs-button>
        <vs-button
          icon
          :size="isMobile ? 'mini' : 'default'"
          @click="onOpenSettings"
          v-if="!isSettingsMode && !isExternal"
          relief
          color="tumblr"
          block
          animation-type="rotate"
        >
          <i class="fas fa-user-cog"></i> Ajustes
          <template #animate>
            <i class="fas fa-user-cog"></i>
          </template>
        </vs-button>
        <vs-button
          icon
          @click="onSave"
          v-else-if="!isExternal && (isEditMode || isSettingsMode)"
          relief
          success
          block
          animation-type="rotate"
        >
          <i class="fas fa-save"></i> Save
          <template #animate>
            <i class="fas fa-save"></i>
          </template>
        </vs-button>
        <br />
        <vs-button
          @click="onFollow"
          v-if="isExternal && !isFollowed"
          icon
          relief
          color="#dc3545"
          block
          animation-type="rotate"
        >
          <i class="fas fa-heart"></i> Follow
          <template #animate>
            <i class="fas fa-heart"></i>
          </template>
        </vs-button>
        <vs-button
          @click="onUnfollow"
          v-if="isExternal && isFollowed"
          icon
          relief
          color="#dc3545"
          block
          animation-type="rotate"
        >
          <i class="fas fa-heart-broken"></i> Unfollow
          <template #animate>
            <i class="fas fa-heart-broken"></i>
          </template>
        </vs-button>
        <br />
        <vs-button
          v-if="isExternal"
          @click="openChat"
          icon
          relief
          color="#5B8200"
          block
          animation-type="vertical"
        >
          Message
          <template #animate> <i class="fas fa-comment-dots"></i> Send </template>
        </vs-button>
      </b-col>
    </b-row>
    <b-row class="contentRow">
      <!-- v-on:activate-tab="scrollTo" -->
      <b-tabs v-model="selectedTab" id="mainTabs" style="width:100%;" justified fill>
        <b-tab :active="selectedTab === 0" title="Creados" style="height: calc(100vh - 2rem)">
          <template v-if="loading" v-slot:title>
            Planes Creados
            <PacmanLoader v-if="loading" color="#dc3545" size="10px" />
          </template>
          <template v-else v-slot:title>
            <iCreate />
            <!-- <div>
              <i class="fas fa-pencil-ruler"></i>
            </div>
            <div>Creados</div> -->
          </template>

          <!-- <b-row class="ownEvents"> -->
          <h3>Planes Creados</h3>
          <CardGrid v-bind:cards="createdEvents" />
          <!-- </b-row> -->
        </b-tab>

        <b-tab :active="selectedTab === 1" title="Participados" lazy>
          <template v-if="loading" v-slot:title>
            Planes Participados
            <PacmanLoader v-if="loading" color="#dc3545" size="10px" />
          </template>
          <template v-else v-slot:title>
            <iParticipate />
            <!-- <div>
              <i class="fas fa-hand-paper"></i>
            </div>
            <div>Participados</div> -->
          </template>
          <!-- <b-row class="participateEvents"> -->
          <h3>Planes Participados</h3>
          <CardGrid v-bind:cards="participatedEvents" :type="1" />
          <!-- </b-row> -->
        </b-tab>
        <b-tab :active="selectedTab === 2" title="Momentos" lazy>
          <template v-if="loading" v-slot:title>
            Momentos
            <PacmanLoader v-if="loading" color="#dc3545" size="10px" />
          </template>
          <template v-else v-slot:title>
            <iMoments />
            <!-- <div>
              <i class="fas fa-camera-retro"></i>
            </div>
            <div>Momentos</div> -->
          </template>
          <div>
            <!-- <h6>Mejores momentos</h6> -->
            <div class="bestMomentsCtn">
              <div class="bestMoments">
                <vs-avatar size="50" class="fleet" history history-gradient>
                  <img height="100%" class="avatimg" :src="this.portadaImg" alt="" />
                </vs-avatar>
                <vs-avatar size="50" history history-gradient>
                  <img height="100%" class="avatimg" :src="this.portadaImg" alt="" />
                </vs-avatar>
                <vs-avatar size="50" history history-gradient>
                  <img height="100%" class="avatimg" :src="this.portadaImg" alt="" />
                </vs-avatar>
                <vs-avatar size="50" history history-gradient>
                  <img height="100%" class="avatimg" :src="this.portadaImg" alt="" />
                </vs-avatar>
                <vs-avatar size="50" history history-gradient>
                  <img height="100%" class="avatimg" :src="this.portadaImg" alt="" />
                </vs-avatar>
                <vs-avatar size="50" history history-gradient>
                  <img height="100%" class="avatimg" :src="this.portadaImg" alt="" />
                </vs-avatar>
              </div>
            </div>
          </div>
          <div>
            <h6>Recientes</h6>
            <div class="momentsCtn">
              <vs-card type="2" v-for="i in 10" :key="'test_' + i">
                <template #title>
                  <h3>Titulo</h3>
                </template>
                <template #img>
                  <img :src="portadaImg" alt="" />
                </template>
                <template #text>
                  <p>
                    Descripcion de la foto
                  </p>
                </template>
                <template #interactions>
                  <vs-button danger icon>
                    <i class="fas fa-heart"></i>
                  </vs-button>
                  <!-- <vs-button class="btn-chat" shadow primary>
                    <i class="bx bx-chat"></i>
                    <span class="span">
                      54
                    </span>
                  </vs-button> -->
                </template>
              </vs-card>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-row>
  </b-container>
</template>

<script>
import CardGrid from "@/components/CardGrid.vue";
import { iCreate, iMoments, iParticipate } from "@/components/ILLUSTRATIONS/";
import {
  loadingMix,
  noficationsDialog,
  authMix,
  eventMix,
  followMix,
  chatMix
} from "@/common/mixin.js";

export default {
  name: "UserContent",
  mixins: [loadingMix, noficationsDialog, authMix, eventMix, followMix, chatMix],
  components: {
    CardGrid,
    iMoments,
    iCreate,
    iParticipate
  },
  props: [
    "isExternal",
    "isFollowed",
    "isEditMode",
    "isSettingsMode",
    "createdEvents",
    "participatedEvents",
    "profileType"
  ],
  methods: {
    async openChat() {
      const { id, username, profileImg } = this.$store.state.currentUser;
      const { creator } = this.$route.params;
      const chat = await this.getChat("single", [id, creator.id]);
      if (!chat) {
        const data = {
          created_at: new Date(),
          participants_id: [id, creator.id],
          participants: [
            { id, username, profileImg: profileImg || null },
            { id: creator.id, username: creator.username, profileImg: creator.profileImg || null }
          ],
          messages: [],
          type: "single"
        };
        const newChat = await this.createChat(data);
        data.id = newChat.id;
        this.openChatMix(data);
      } else {
        this.openChatMix(chat);
      }
    },
    onEdit() {
      this.$store.commit("showUserSettingsSB", true);
      // this.isEditMode = true;
      this.$emit("onEditParent", true);
    },
    // FOLLOW
    async onUnfollow() {
      const { id } = this.$store.state.currentUser;
      const { creator } = this.$route.params;
      const deletedInd = this.user.followDetail.findIndex(detail => detail.created_by === id);
      this.user.followDetail.splice(deletedInd, 1);
      this.user.followers -= 1;
      this.firebaseUpdateUser(this.user.id || creator.id, this.user);
      this.isFollowed = false;
    },
    async onFollow() {
      const { id, profileImg, username } = this.$store.state.currentUser;
      const { creator } = this.$route.params;
      let { followers } = this.user;
      if (id) {
        const myFollow = [
          {
            created_by: id,
            created_at: new Date(),
            username,
            authorImg: profileImg
          }
        ];
        this.user.followers = followers >= 0 ? followers + 1 : 1;
        this.user.followDetail = this.user.followDetail
          ? [...new Set(this.user.followDetail.concat(...myFollow))]
          : myFollow;
        this.firebaseUpdateUser(this.user.id || creator.id, this.user);
        this.isFollowed = true;
        this.sendPush(`${username} es tu nuevo follower`, [creator.id], "Follow");
        this.notificateLoginSuccess("Followed con exito", "Ahora sigues a  " + creator.username);
      }
    },

    onOpenSettings() {
      this.$emit("onOpenSettings");
      this.$store.commit("showAccountSettingsSB", true);
    },

    onSave() {
      this.$emit("onSave");
    }
  },
  data: () => {
    return {
      selectedTab: 0,
      // isSettingsMode: false,
      isMobile: false,
      loading: false,
      portadaImg:
        "https://c0.wallpaperflare.com/preview/217/9/286/fun-event-crowd-entertainment.jpg"
    };
  },
  mounted() {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.isMobile = isMobile;
    }
  }
};
</script>

<style scoped>
.mBtnsCol {
  display: flex;
}

.infoRow {
  flex-direction: row-reverse;
  padding: 0rem 0 0 0;
}

.bestMoments {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  align-items: center;
  padding: 0rem 1rem;
}

.momentsCtn {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  align-items: center;
  padding: 1rem;
}

.avatimg {
  width: auto;
  height: 100%;
}
</style>
