<template>
  <div>
    <UserSettingsSideBar @onSave="onSave" @onEditParent="onEditParent" :open="true" />
    <AccountSettingsSideBar @onSave="onSaveSettings" :open="true" />
    <b-row class="portadaRow" :style="isEditMode === null ? 'cursor:pointer;' : null">
      <div class="portada"></div>
      <div class="avatar">
        <b-form-file
          ref="avatarInput"
          class="inputAvatar"
          :state="Boolean(true)"
          accept="image/*"
          @change="onUploadFile($event, 'avatar')"
        >
        </b-form-file>
        <UserCard
          @onChangeImg="onChangeImg"
          :user="user"
          :isExternal="isExternal"
          :isEditMode="isEditMode"
          :avatarImg="avatarImg"
        />
      </div>
    </b-row>
    <UserContent
      @onEditParent="onEditParent"
      @onOpenSettings="onOpenSettings"
      @onSave="onSave"
      :profileType="profileType"
      :createdEvents="createdEvents"
      :participatedEvents="participatedEvents"
      :isEditMode="isEditMode"
      :isExternal="isExternal"
      :isFollowed="isFollowed"
      :isSettingsMode="isSettingsMode"
      :portadaImg="portadaImg"
    />
  </div>
</template>

<script>
import FollowersList from "@/components/FollowersList.vue";
import UserSettingsSideBar from "@/components/SIDEBARS/UserSettingsSB.vue";
import AccountSettingsSideBar from "@/components/SIDEBARS/AccountSettingsSB.vue";
import UserCard from "@/components/CARDS/UserCard.vue";
import UserContent from "@/components/PROFILE/userContent.vue";
import {
  loadingMix,
  noficationsDialog,
  authMix,
  eventMix,
  followMix,
  chatMix
} from "@/common/mixin.js";
import { mapState } from "vuex";

export default {
  name: "Profile",
  mixins: [loadingMix, noficationsDialog, authMix, eventMix, followMix, chatMix],
  components: {
    UserSettingsSideBar,
    AccountSettingsSideBar,
    FollowersList,
    UserCard,
    UserContent
  },
  methods: {
    onInit(params = null) {
      const { id } = this.$store.state.currentUser;
      // console.log(this.$store.state.currentUser);
      this.$route.params.creator = params
        ? params.creator || this.$route.params.creator
        : this.$route.params.creator;
      const { creator } = this.$route.params;
      if (creator && creator.id && id) {
        this.isExternal = creator.id !== id;
        if (this.isExternal) {
          this.getProfileData(creator.id);
          // this.getFollowing(creator.id, id);
        } else {
          this.getProfileData(id);
        }
      } else {
        this.isExternal = false;
        this.getProfileData(id);
      }
    },

    checkFollowed() {
      const { currentUser } = this.$store.state;
      const { id } = currentUser;
      const ind = this.user.followDetail
        ? this.user.followDetail.findIndex(detail => detail.created_by === id)
        : -1;
      this.isFollowed = ind > -1;
    },

    onEditParent(a) {
      this.isEditMode = !this.isEditMode;
    },

    async onSave(data) {
      if (this.isSettingsMode) {
        this.onSaveSettings();
      }
      const currentUser = await this.firebaseCurrentUser();
      if (this.previewImgFile && currentUser) {
        await this.firebaseSetProfileImg(currentUser.uid, this.previewImgFile);
      } else if (data) {
        const { password } = data;
        if (password && password !== "") {
          currentUser.updatePassword(password);
        } else {
          delete data.password;
        }
        await this.firebaseUpdateUser(currentUser.uid, data);
      }
      const user = await this.firebaseGetUser(currentUser.uid);
      this.$store.commit("setUser", user);
      this.isEditMode = false;
    },
    
    onOpenSettings() {
      this.isSettingsMode = true;
    },

    onSaveSettings() {
      this.isEditMode = false;
      this.isSettingsMode = false;
    },

    onChangeImg({ event, type }) {
      event.stopPropagation();
      event.cancelBubble = true;
      if (this.isEditMode && type === "avatar") {
        this.$refs.avatarInput.$el.children[0].click();
      } else if (this.isEditMode && type === "portada") {
        this.$refs.portadaInput.$el.children[0].click();
      }
    },

    onUploadFile(oEvent, type) {
      const image = oEvent.target.files[0];
      this.previewImgFile = image;
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = oEvent2 => {
        this.previewImage = oEvent2.target.result;
        if (type === "avatar") {
          this.avatarImg = oEvent2.target.result;
        } else {
          this.portadaImg = oEvent2.target.result;
        }
      };
      oEvent.stopPropagation();
      oEvent.cancelBubble = true;
    },

    async getProfileData(externalUser = null) {
      if (externalUser) {
        this.user = await this.firebaseGetUser(externalUser);
        this.profileType = this.user.type;
        this.getEvents(externalUser);
        this.checkFollowed();
        try {
          this.avatarImg = await this.firebaseGetProfileImg(externalUser);
        } catch (error) {
          console.log(error);
        }
      } else {
        const currentUser = await this.firebaseCurrentUser();
        if (currentUser) {
          this.user = await this.firebaseGetUser(currentUser.uid);
          console.log(this.user);
          this.getEvents(currentUser.uid);
          this.avatarImg = await this.firebaseGetProfileImg(currentUser.uid);
        }
      }
    },

    async getFollowing(followed_Id, follower) {},

    async getEvents(user_uid) {
      this.createdEvents = await this.getFilteredEvents("created_by", user_uid);
      this.participatedEvents = await this.getFilteredEvents(
        "participants",
        user_uid,
        "array-contains"
      );
    }
  },
  computed: mapState(["currentUser", "showUserSettingsSB"]),
  watch: {
    currentUser(newValue, oldValue) {
      if (newValue) {
        this.user = newValue;
        this.avatarImg = newValue.profileImg;
        this.getProfileData(newValue.id);
      }
    },
    showUserSettingsSB(newValue, oldValue) {
      // this.isEditMode = newValue;
    }
  },
  mounted() {
    const mainTabs = document.getElementById("mainTabs").children[1];
    mainTabs.style.overflow = "auto";

    window.scrollTo(0, 0);
    this.onInit();
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.isMobile = isMobile;
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.onInit(to.params);
    next();
  },
  data() {
    return {
      user: null,
      profileType: 1,
      createdEvents: [],
      participatedEvents: [],
      aFollowers: [],
      isFollowed: false,
      isExternal: true,
      isEditMode: false,
      isSettingsMode: false,
      previewImgFile: null,
      avatarImg: null,
      privateImg:
        "https://p1.pxfuel.com/preview/272/573/172/iphone-taking-photo-phone-anonymous-royalty-free-thumbnail.jpg",
      portadaImg:
        "https://c0.wallpaperflare.com/preview/217/9/286/fun-event-crowd-entertainment.jpg"
    };
  }
};
</script>

<style scoped>
@media (max-width: 575px) {
  .infoRow,
  .contentRow {
    width: 100%;
    min-height: 200px !important;
  }

  .btnCol {
    padding: 0;
  }

  .hobbies {
    width: 300px;
  }

  .portada {
    width: 100%;
    height: 300px;
    margin: auto;
    background-size: cover;
    background-image: url("https://www.elllobregat.com/fotos/80/festival_pas.jpg");
    /* url("https://c0.wallpaperflare.com/preview/217/9/286/fun-event-crowd-entertainment.jpg"); */
  }

  .avatarImg {
    right: 60%;
  }

  .avatar {
    background-image: url(https://static.thenounproject.com/png/207964-200.png);
    background-size: cover;
    position: absolute;
    top: 55px;
    /* right: 74%; */
    left: 10px;
  }

  div[role="tabpanel"] {
    overflow: auto;
  }

  .portadaRow {
    margin: 0;
  }

  .type-1 {
    text-align: start;
  }
}

@media (min-width: 576px) {
  .infoRow,
  .contentRow {
    width: 80%;
  }

  .avatar {
    background-image: url("https://static.thenounproject.com/png/207964-200.png");
    background-size: cover;
    position: absolute;
    top: 30%;
    /* right: 74%; */
    left: 1%;
  }
}

.ownEvents,
.participateEvents,
.followers,
.hobbies,
.prices {
  padding: 1rem;
  box-shadow: 5px 3px 20px 0px #17a2b8;
  border-radius: 2rem;
  margin: 1rem;
}

.portada {
  /* background-image: url("https://c0.wallpaperflare.com/preview/217/9/286/fun-event-crowd-entertainment.jpg"); */
  background-image: url("https://www.elllobregat.com/fotos/80/festival_pas.jpg");
  background-position-y: center;
  background-size: cover;
  width: 100%;
  filter: brightness(0.5);
  z-index: -1;
}

.portada > img {
  max-height: 300px;
  min-width: 80%;
}

.portadaRow {
  height: 300px;
}

.infoRow,
.contentRow {
  background: white;
  min-height: 300px;
  /* width: 80%; */
  margin: auto;
}

.inputAvatar {
  display: none;
}

.avatar > img {
  height: 200px;
}

.avatarImg,
.portadaImg {
  filter: opacity(0.5);
  cursor: pointer;
}

.col1 {
  text-align: start;
  margin-top: 4rem;
}

.intereses {
  display: flex;
}

br {
  display: contents;
}

.bestMoments {
  display: flex;
  justify-content: space-evenly;
}
</style>
